import React from "react";
import {
  FaFacebookF as Facebook,
  FaTwitter as Twitter,
  FaLinkedinIn as LinkedIn,
  FaInstagram as Instagram,
} from "react-icons/fa";
import { Link } from "gatsby";

export default function SocialIcons() {
  return (
    <>
      <a
        href="https://facebook.com/paranursing"
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </a>
      <a
        href="https://twitter.com/paranursing"
        target="_blank"
        rel="noreferrer"
      >
        <Twitter />
      </a>
      <a
        href="https://linkedin.com/company/paranursing"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedIn />
      </a>
      <a
        href="https://instagram.com/paranursing"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
      </a>
    </>
  );
}
