import React from "react";
import { Link } from "gatsby";

export default function Header() {
  return (
    <header>
      <div className="headerMain">
        <div className="container">
          <a className="logo headerLogo" href="/">
            Para
          </a>
          <input className="menuBtn" type="checkbox" id="menu-btn" />
          <input className="menuBtn" type="checkbox" id="menu-btn" />
          <label className="menuIcon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li className="menu-item">
              <Link to="/#work">Work</Link>
            </li>
            <li className="menu-item">
              <Link to="/#hire">Hire</Link>
            </li>
            <li className="menu-item">
              <Link to="/#faq">FAQ</Link>
            </li>
            <li className="menu-item">
              <Link to="/resources/">Resources</Link>
            </li>
            <li className="menu-item">
              <a
                href="mailto:admin@joinpara.com"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </li>
            <li className="menu-item menu-button">
              <a href="https://app.joinpara.com">Facility Login</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
