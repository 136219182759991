import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import * as styles from "../styles/blogTemplate.module.css";
import SocialIcons from "../components/SocialIcons/SocialIcons";

export default function Template({ data }) {
  const { title, content, Seo, slug } = data.strapiBlog;
  const { media, alt } = Seo.ShareImage;
  const pageContent =
    content[0] === "<" ? (
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    ) : (
      <div className={styles.content}>
        <ReactMarkdown source={content} />
      </div>
    );

  return (
    <div className="blog-post-container">
      <Helmet>
        <title>{Seo.metaTitle}</title>
        <meta name="description" content={Seo.metaDescription} />
        <link
          rel="canonical"
          href={"https://joinpara.com/resources/" + slug + "/"}
        />
      </Helmet>
      <Header />
      <div className={styles.blogPost}>
        <header>
          <img src={media.url} alt={alt} className={styles.header_img} />
        </header>
        <main className={styles.main}>
          <div className={styles.icons}>
            <SocialIcons />
          </div>
          <h1 className={styles.title}>{title}</h1>
          {pageContent}
        </main>
      </div>
      <Footer />
    </div>
  );
}

export const pageQuery = graphql`
  query MyQuery($slug: String!) {
    strapiBlog(slug: { eq: $slug }) {
      title
      content
      slug
      Seo {
        ShareImage {
          media {
            url
          }
          alt
        }
        id
        metaDescription
        metaTitle
      }
    }
  }
`;
