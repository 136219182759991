import React from "react";
import ImportScript from "../../hooks/ImportScript";
import { Link } from "gatsby";
import * as styles from "./Footer.module.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className={styles.section6} id="signup">
          <div className="container section-container">
            <div className="text-center">
              <div className="_form_7"></div>
              {ImportScript(
                "https://joinpara.activehosted.com/f/embed.php?id=7"
              )}
            </div>
          </div>
        </div>
        <div className={styles.footerMain}>
          <div className="container">
            <Link className={`logo ${styles.footerLogo}`} to={"/"}>
              Para
            </Link>
            <ul className="menu">
              <li className="menu-item">
                <a href="/#work">Work</a>
              </li>
              <li className="menu-item">
                <a href="/#hire">Hire</a>
              </li>
              <li className="menu-item">
                <Link to="/privacy-policy/#policy">Privacy Policy</Link>
              </li>
              <li className="menu-item">
                <Link to="/terms-and-conditions/#terms">
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.socket}>
            <span>&copy; 2023 Para Services, Inc.</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
